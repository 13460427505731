import * as React from "react"
import { Box, Typography } from "@mui/material";
import { Link } from "gatsby-theme-material-ui";
import { StandardPageContainer } from "../../components/reviewskew/standardpage/StandardPageContainer";


const NotFoundPage = () => {
  return (
    <StandardPageContainer title="This Brand Coming Soon">
      <Box textAlign="center">
        <Typography variant="h1">
          Coming Soon!
        </Typography>
        
        <Typography>
          Sorry, this page isn't quite ready yet, but we're working on it! Check back soon for more reviews for this brand.
        </Typography>
        <Typography>
          In the mean time you can head back to the <Link to="/">home page</Link> or check out some <Link href="/recent-reviews/">recent review summaries</Link>.
        </Typography>
      </Box>
    </StandardPageContainer>
  )
}

export default NotFoundPage
